// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyA0k0jUJQjPdW1e8UrkMRDqSPIUykPuYV8",
//   authDomain: "notification-sys-72b68.firebaseapp.com",
//   projectId: "notification-sys-72b68",
//   storageBucket: "notification-sys-72b68.firebasestorage.app",
//   messagingSenderId: "806849389631",
//   appId: "1:806849389631:web:8c924fc35fe0eb3b25c48a",
//   measurementId: "G-KPBZPVKTYW",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

// // Initialize Firebase Cloud Messaging and get a reference to the service
// export const notificationMessaging = getMessaging(app);

// export const generateToken = async () => {
//   const permission = await Notification.requestPermission();
//   if (permission === "granted") {
//     console.log("Notification permission granted.");
//     const token = await getToken(notificationMessaging, {
//       vapidKey:
//         "BDauyh5whnAxHpnL78m0NY-mZJ8XjfinaGGzn4KTeLCgvCfPIH-1YolYP5AVbHv7NXixw-UZwppivc2CYRnWXLI",
//     });
//     console.log("Token generated: ", token);
//   } else {
//     console.log("Unable to get permission to notify.");
//   }
// };

import { getApp, getApps, initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

// Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0k0jUJQjPdW1e8UrkMRDqSPIUykPuYV8",
  authDomain: "notification-sys-72b68.firebaseapp.com",
  projectId: "notification-sys-72b68",
  storageBucket: "notification-sys-72b68.firebasestorage.app",
  messagingSenderId: "806849389631",
  appId: "1:806849389631:web:8c924fc35fe0eb3b25c48a",
  measurementId: "G-KPBZPVKTYW",
};

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

const messaging = async () => {
  const supported = await isSupported();
  return supported ? getMessaging(app) : null;
};

export const fetchToken = async () => {
  try {
    const fcmMessaging = await messaging();
    if (fcmMessaging) {
      const token = await getToken(fcmMessaging, {
        // vapidKey: process.env.NEXT_PUBLIC_FIREBASE_FCM_VAPID_KEY,
        vapidKey:
          "BDauyh5whnAxHpnL78m0NY-mZJ8XjfinaGGzn4KTeLCgvCfPIH-1YolYP5AVbHv7NXixw-UZwppivc2CYRnWXLI",
      });
      console.log("Token generated: ", token);
      return token;
    }
    return null;
  } catch (err) {
    console.error("An error occurred while fetching the token:", err);
    return null;
  }
};

const msg = await messaging();

export { app, msg };
