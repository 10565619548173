import { Component, useEffect, useState } from "react";
import "./App.css";
import { Pagex } from "./pages/index";
import NavTailwind from "./components/nav_bar/nav_bar_tailwind";
// import markdown_file from "./data/README.md";

import bg_Img from "./data/images/dark_color_vivid_bg.png";
import { splash_imgae } from "./data/data";
import { FooterBar } from "./components/footer_bar/footer_bar";
import { useAppSelector } from "./store/store";
import { fetchToken, msg as messaging } from "./add-ons/notifications/firebase";
import { onMessage } from "firebase/messaging";

export const App = () => {
  const isLogin = useAppSelector((state) => state.log_in_status);

  const [state, setState] = useState<{ bg_img: string }>({
    bg_img: bg_Img,
  });

  const change_wallpaper = () => {
    new Notification("notificationTitle");

    splash_imgae().then((m) => {
      if (m && m[0]) {
        setState({ ...state, bg_img: m[0]?.urls?.full });
      }
    });
  };  
  
  
  useEffect(() => {
    change_wallpaper();
    fetchToken();
    onMessage(messaging!, (payload) => {
      console.log("Message received. ", payload);
      // alert("Message received. " + payload.notification?.title);
      const notificationTitle = payload.notification?.title || "No Title";
      const notificationOptions = {
        body: payload.notification?.body,
        icon: payload.notification?.icon,
      };
      new Notification(notificationTitle, notificationOptions);

      if (Notification.permission === "granted") {
        new Notification(notificationTitle, notificationOptions);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification(notificationTitle, notificationOptions);
          }
        });
      }
      // ...
    });
    return () => {};
  }, []);

  return (
    <div key={"MAIN_APP"} className="App">
      <NavTailwind />
      <main
        className="main-wrapper"
        role="main"
        style={{ backgroundImage: `url(${state.bg_img})` }}
      >
        <section id="Admin-Section">
          {isLogin === true ? (
            <>
              <Pagex />
              {/* <CustomRightClick /> */}
            </>
          ) : (
            <div className="login-page flex h-full justify-center overflow-x-scroll bg-white">
              <div>
                {/* <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    children={this.state.readme}
                  /> */}
              </div>
            </div>
          )}
        </section>
      </main>
      <FooterBar change_wallpaper={change_wallpaper} />
    </div>
  );
};

export default App;
